var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wrapper"},[_c('StepCount',{class:{ bank_v: !_vm.checkCookie },attrs:{"step":1}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"step",class:{ bank_v: !_vm.checkCookie }},[_c('h6',[_vm._v(_vm._s(_vm.config.dictionary.primary.step))]),_c('h2',[_vm._v(_vm._s(_vm.config.dictionary.primary.title))]),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"input-wrapper",class:{
            invalid: _vm.$v.phone.$error,
            valid: !_vm.$v.phone.$invalid
          }},[_c('label',{staticClass:"label",attrs:{"for":"phone"}},[_vm._v(" "+_vm._s(_vm.config.dictionary.common.fields.phone)+" ")]),_c('IMaskComponent',{ref:"phone",staticClass:"input",attrs:{"maxlength":"16","inputmode":"numeric","id":"phone","mask":'+{7}({9}00)000-00-00'},on:{"blur":function($event){return _vm.onTouchFunc('phone', $event.target.value)},"input":function($event){return _vm.onResetFunc('phone')},"complete":function($event){return _vm.onCompleteFunc('email')}},model:{value:(_vm.$v.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.phone.$model"}}),(_vm.$v.phone.$error)?_c('small',[_vm._v(" "+_vm._s(_vm.config.dictionary.common.fieldsError.errorPhone)+" ")]):_vm._e()],1),_c('div',{staticClass:"input-wrapper",class:{
            invalid: _vm.$v.email.$error,
            valid: !_vm.$v.email.$invalid
          }},[_c('label',{staticClass:"label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.config.dictionary.common.fields.email)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],ref:"email",staticClass:"input",attrs:{"type":"email","id":"email"},domProps:{"value":(_vm.$v.email.$model)},on:{"blur":[function($event){return _vm.onTouchFunc('email', $event.target.value)},function($event){return _vm.$forceUpdate()}],"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},function($event){return _vm.onResetFunc('email')}]}}),(_vm.$v.email.$error)?_c('small',[_vm._v(" "+_vm._s(_vm.config.dictionary.common.fieldsError.errorEmail)+" ")]):_vm._e()]),_c('div',{staticClass:"checkbox-wrapper"},[_c('input',{attrs:{"type":"checkbox","name":"acceptance","id":"acceptance"},domProps:{"checked":_vm.acceptance},on:{"change":function($event){return _vm.$emit('acceptance-change', $event)}}}),_c('label',{staticClass:"checkbox-label",attrs:{"for":"acceptance"},domProps:{"innerHTML":_vm._s(_vm.checkAcceptText)}})]),(
            _vm.config.siteConfig.acceptanceAutoPayment !== undefined &&
              _vm.config.siteConfig.acceptanceAutoPayment.length &&
              !_vm.checkCookie
          )?_c('div',{staticClass:"checkbox-wrapper auto-payment"},[_c('input',{attrs:{"type":"checkbox","name":"acceptanceAutoPayment","id":"acceptance-auto-payment"},domProps:{"checked":_vm.acceptanceAutoPayment},on:{"change":function($event){return _vm.$emit('acceptance-change', $event)}}}),_c('label',{staticClass:"checkbox-label",attrs:{"for":"acceptance-auto-payment"},domProps:{"innerHTML":_vm._s(_vm.config.siteConfig.acceptanceAutoPayment)}})]):_vm._e(),(!_vm.checkCookie)?_c('div',{staticClass:"checkbox-wrapper auto-payment"},[_c('input',{attrs:{"type":"checkbox","name":"acceptAdInput","id":"acceptAdInput"},domProps:{"checked":_vm.acceptAd},on:{"change":function($event){return _vm.$emit('acceptance-ad', $event)}}}),_c('label',{staticClass:"checkbox-label",attrs:{"for":"acceptAdInput"},domProps:{"innerHTML":_vm._s(_vm.acceptAdText)}})]):_vm._e(),_c('button',{staticClass:"button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.config.dictionary.common.btnsText.continue)+" ")])])]),(_vm.checkCookie)?_c('div',{staticClass:"info"},[_c('h3',[_vm._v(" "+_vm._s(_vm.config.dictionary.common.laonInfo.title)+" "),(_vm.timerInfo !== null)?_c('span',{staticClass:"timer_info"},[_vm._v(" Осталось "),_c('b',[_vm._v(_vm._s(_vm.timerInfo))])]):_vm._e()]),_c('RangeSlider',{attrs:{"loanSumStart":_vm.getme.loanSum,"loanSumCalcStep":_vm.config.frontConfig.loanSumCalcStep,"loanSumMin":_vm.config.frontConfig.loanSumMin,"loanSumMax":_vm.config.frontConfig.loanSumMax,"loanTermStart":_vm.getme.loanTerm,"loanTermCalcStep":_vm.config.frontConfig.loanTermCalcStep,"loanTermMin":_vm.config.frontConfig.loanTermMin,"loanTermMax":_vm.config.frontConfig.loanTermMax,"rangeSlider":_vm.config.dictionary.common.rangeSlider}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }